import {
  Button,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import React, { useState, useRef, useMemo } from 'react'
import {
  useGetBrandNamesQuery,
  useUploadNewAlertMutation,
  Order_By,
  useGetSpoofingReportsLazyQuery,
} from '../../generated/graphql'
import { useOrgID, getOrgData, useIsEmployeeView } from '../../hooks/id_token_claims'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_ERROR,
  DOPPEL_SUCCESS,
} from '../../utils/style'
import SideDrawer from '../shared/side_drawer'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { UploadedReport } from './form_components/uploaded_report'
import { ErrorReportReason } from './form_components/error_report_reason'
import { IoMdImages } from 'react-icons/io'
import { ReportType } from '@/utils/constants'
import { useSelectedReportType } from '@/pages/reports'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { getExternalReportId } from '@/utils/reports/report_utils'

const MAX_URLS_UPLOADED_INTERNAL = 100
const MAX_URLS_UPLOADED_EXTERNAL = 30

export default function UploadNewReportModal({ isOpen, onClose, refreshFunc }) {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [flaggedUrls, setFlaggedUrls] = useState('')
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [notes, setNotes] = useState('')
  const [isCustomerSourcedSelection, setIsCustomerSourcedSelection] = useState(null) // internal only
  const [reports, setReports] = useState([])
  const [errors, setErrors] = useState([])
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()
  const { data: brands } = useGetBrandNamesQuery({
    variables: {
      where: { organization_id: { _eq: orgId } },
      order_by: [{ entity_name: Order_By.Asc }],
    },
  })
  // for fetching report data after upload
  const [getSpoofingReports, { data: uploadedReportsData }] =
    useGetSpoofingReportsLazyQuery({
      fetchPolicy: 'network-only',
    })
  const uploadedReportsMap: Record<string, any> = useMemo(() => {
    if (!uploadedReportsData) return null
    return uploadedReportsData.spoofing_reports.reduce((acc, report) => {
      acc[report.id] = report
      return acc
    }, {})
  }, [uploadedReportsData])
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(null)
  const firstField = useRef()

  const [uploadNewAlertMutation] = useUploadNewAlertMutation()

  const maxUrlsUploaded = isEmployeeView
    ? MAX_URLS_UPLOADED_INTERNAL
    : MAX_URLS_UPLOADED_EXTERNAL
  const isCustomerSourced = isEmployeeView ? isCustomerSourcedSelection : true

  const reset = () => {
    setFlaggedUrls('')
    setSelectedBrand(null)
    setIsCustomerSourcedSelection(null)
    setIsUploading(false)
    setReports([])
    setErrors([])
    setError(null)
    setNotes('')
  }

  const onSubmit = () => {
    setIsUploading(true)

    const splitUrls = flaggedUrls.split('\n').filter((url) => url.trim() !== '')
    const reportSubmissions = splitUrls.map((url) => {
      return uploadNewAlertMutation({
        variables: {
          entity_value: url,
          organization_id: orgData && orgData.id,
          brand_id: selectedBrand && selectedBrand.id,
          is_customer_sourced: isCustomerSourced,
          notes: notes,
        },
      })
    })

    Promise.all(reportSubmissions)
      .then((results) => {
        const allReports = []
        const allErrors = []

        results.forEach((result) => {
          if (result.data?.upload_new_alert_action?.alert) {
            allReports.push(result.data.upload_new_alert_action.alert)
          }
          if (result.data?.upload_new_alert_action?.error) {
            allErrors.push(result.data.upload_new_alert_action.error)
          }
        })

        getSpoofingReports({
          variables: {
            spoofingReportsWhere: {
              id: { _in: allReports.map((report) => report.id) },
            },
            orderBy: [],
          },
        })
        setReports(allReports)
        setErrors(allErrors)
        setIsUploading(false)
        setFlaggedUrls('')

        setTimeout(() => {
          refreshFunc()
        }, 1500)
      })
      .catch((error) => {
        setIsUploading(false)
        setError(error)
      })
  }

  if (selectedReportType === ReportType.DARK_WEB) {
    return <></>
  }

  return (
    <SideDrawer
      firstField={firstField}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
    >
      <Stack spacing="24px">
        <Heading as="h2" size="md">
          Upload New Alert
        </Heading>

        <FormControl isRequired>
          <FormLabel>Entities</FormLabel>

          <Textarea
            minHeight="200px"
            onChange={(e) => setFlaggedUrls(e.target.value)}
            placeholder={'google.com\nbadwebsite.com\n+1 525 863 2158\netc...'}
            ref={firstField}
            value={flaggedUrls}
          />

          <FormHelperText>
            New line separated list of entities to create alerts for. (Max{' '}
            {maxUrlsUploaded})
          </FormHelperText>
        </FormControl>

        {isEmployeeView && (
          <FormControl isRequired>
            <FormLabel>Attribution</FormLabel>

            <FormHelperText>Uploading on behalf of {orgData.name}?</FormHelperText>

            <RadioGroup
              onChange={(value) => setIsCustomerSourcedSelection(value === 'yes')}
            >
              <Radio isChecked={isCustomerSourcedSelection === true} mt={2} value="yes">
                Yes, this is a request from {orgData.name}.
              </Radio>

              <Radio isChecked={isCustomerSourcedSelection === false} mt={2} value="no">
                No, I or another Doppel employee discovered these threats.
              </Radio>
            </RadioGroup>
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Brand</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Brand'}
            icon={<IoMdImages size="18" />}
            isMultiSelect={false}
            items={
              brands ? brands.spoofing_entities.map((brand) => brand.entity_name) : []
            }
            placeholder={'Search Brands...'}
            selectedItems={[selectedBrand?.entity_name]}
            setSelectedItems={([brandName]) => {
              const selectedBrand = brands.spoofing_entities?.find(
                (b) => b.entity_name === brandName,
              )
              setSelectedBrand(selectedBrand)
            }}
            showSearchBar={true}
          />

          <FormHelperText>Brand to action all entities under</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Notes</FormLabel>

          <Textarea
            minHeight="100px"
            onChange={(e) => setNotes(e.target.value)}
            placeholder={'Add notes here...'}
            value={notes}
          />

          <FormHelperText>Notes for uploaded alert(s).</FormHelperText>
        </FormControl>

        <br />

        <Button
          _hover={{ bgColor: DOPPEL_CYBER_BLUE_SHADE }}
          bgColor={error ? DOPPEL_ERROR : DOPPEL_CYBER_BLUE}
          color={'white'}
          isDisabled={
            error ||
            isUploading ||
            !validateUploadData(flaggedUrls, maxUrlsUploaded, isCustomerSourced)
          }
          isLoading={isUploading}
          loadingText="Uploading"
          onClick={onSubmit}
        >
          {error ? 'Error uploading' : 'Upload'}
        </Button>

        <Collapse animateOpacity in={uploadedReportsMap && reports.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_SUCCESS}
            borderRadius="lg"
            direction="column"
            padding="4"
            textColor={'white'}
          >
            {reports.map((report) => {
              const uploadedReport = uploadedReportsMap?.[report.id]
              if (!uploadedReport) return null
              const displayId = uploadedReport.external_id
                ? getExternalReportId(orgData.abbr_name, uploadedReport.external_id)
                : uploadedReport.id // fallback for now when we still get null external_ids
              return (
                <UploadedReport
                  brand_name={report.brand_name}
                  display_id={displayId}
                  flagged_url={report.entity_value}
                  is_existing={report.is_existing}
                  key={report.id}
                  platform_subtype_name={uploadedReport.platform_subtype?.name}
                  product={report.product}
                  status={report.queue_state}
                />
              )
            })}
          </Flex>
        </Collapse>

        <Collapse animateOpacity in={errors.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_ERROR}
            borderRadius="lg"
            direction="column"
            marginTop={2}
            padding="4"
            textColor={'white'}
          >
            {errors.map((error) => {
              return (
                <ErrorReportReason
                  flagged_url={error.entity_value}
                  key={error.entity_value}
                  reason={error.reason}
                />
              )
            })}
          </Flex>
        </Collapse>
      </Stack>
    </SideDrawer>
  )
}

function validateUploadData(flaggedUrls, maxUrlsUploaded, isCustomerSourced) {
  if (isCustomerSourced === null) {
    return false
  }
  if (flaggedUrls.trim() === '') {
    return false
  }

  const urls = flaggedUrls
    .replace(/\r\n|\r|\n/g, '\n') // normalize newline characters
    .split('\n')
    .filter((url) => url.trim() !== '')

  if (urls.length > maxUrlsUploaded) {
    return false
  }

  return urls.every((url) => {
    return !!parsePhoneNumberFromString(url) || url.includes('.')
  })
}
