import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  Input,
  Collapse,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import {
  useCreateDarkWebCreditCardReportMutation,
  useGetPlatformSubtypesByNameQuery,
} from '../../../generated/graphql'
import { useOrgID, getOrgData, useUserID } from '../../../hooks/id_token_claims'
import SideDrawer from '../../shared/side_drawer'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_ERROR,
  DOPPEL_SUCCESS,
} from '../../../utils/style'
import { useSelectedReportType } from '@/pages/reports'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import {
  PlatformName,
  PlatformSubtype,
  ReportClassification,
  ReportSource,
  ReportStatus,
} from '@/generated/enums'
import { Order_By, useGetBrandNamesQuery } from '@/generated/graphql'
import DropdownMenu from '../../shared/forms/dropdown_menu'
import { IoMdImages } from 'react-icons/io'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useAtom } from 'jotai'
import { ErrorReportReason } from '../form_components/error_report_reason'
import { UploadedDarkwebReport } from '../form_components/uploaded_darkweb_report'
import { v4 as uuidv4 } from 'uuid'
import { parseYearMonthToLastDate } from './utils'

export default function UploadDarkWebCreditCardReportModal({
  isOpen,
  onClose,
  refreshFunc,
}) {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const [flaggedUrl, setflaggedUrl] = useState('')
  const [creditCardNumber, setCreditCardNumber] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const [errors, setErrors] = useState([])
  const [error, setError] = useState(null)
  const [report, setReport] = useState(null)
  const [cardholderName, setCardholderName] = useState('')
  const [cardholderAddress, setCardholderAddress] = useState('')
  const firstField = useRef()

  const [createDarkWebCreditCardReport] = useCreateDarkWebCreditCardReportMutation()
  const { data: subtypeData } = useGetPlatformSubtypesByNameQuery({
    variables: { name: PlatformSubtype.CREDIT_CARD_LEAKS },
  })
  const creditCardLeaksPlatformSubtypeId = subtypeData?.platform_subtypes?.[0]?.id

  const { data: brands } = useGetBrandNamesQuery({
    variables: {
      where: { organization_id: { _eq: orgId } },
      order_by: [{ entity_name: Order_By.Asc }],
    },
  })

  if (
    selectedReportType !== ReportType.DARK_WEB ||
    selectedReportExposureType !== DarkWebPlatforms.CREDIT_CARD_LEAKS
  ) {
    return <></>
  }

  const reset = () => {
    setflaggedUrl('')
    setCreditCardNumber('')
    setExpirationDate('')
    setSelectedBrand(null)
    setIsUploading(false)
    setErrors([])
    setError(null)
    setReport(null)
  }

  const onSubmitDarkWebCreditCard = () => {
    if (!creditCardLeaksPlatformSubtypeId) return
    setReport(null)
    setError(null)
    setErrors([])

    setIsUploading(true)

    const darkWebId = uuidv4()

    const submitInput = {
      organization_id: orgData && orgData.id,
      brand_id: selectedBrand && selectedBrand.id,
      flagged_url: darkWebId.toString(),
      type: PlatformName.CREDIT_CARD_LEAKS,
      platform_subtype_id: creditCardLeaksPlatformSubtypeId,
      source: ReportSource.UI_UPLOAD,
      report_status: ReportStatus.NEEDS_ACTION,
      spoofing_status: ReportClassification.SUSPICIOUS,
      uploader_id: userId,
      suspicion_score: 10,
      classification: ReportClassification.SUSPICIOUS,
      location: flaggedUrl,
      dark_web_id: darkWebId,
      dark_web_network: null,
      credit_card_number: creditCardNumber.slice(6) || null,
      bin: Number(creditCardNumber.slice(0, 6)),
      expiration_date: parseYearMonthToLastDate(expirationDate),
      cardholder_name: cardholderName,
      cardholder_address: cardholderAddress,
    }

    const reportSubmissions = createDarkWebCreditCardReport({
      variables: submitInput,
    })
      .then((result) => {
        const resultingReport = result.data?.insert_spoofing_reports?.returning?.[0]
        if (resultingReport) {
          setReport(resultingReport)
          setflaggedUrl('')

          setTimeout(() => {
            refreshFunc()
          }, 1500)
        } else if (result?.errors) {
          setError(result.errors)
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  return (
    <SideDrawer
      firstField={firstField}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
    >
      <Stack spacing="24px">
        <Heading as="h2" size="md">
          Upload Dark Web Credit Card Alert
        </Heading>

        <FormControl>
          <FormLabel>Brand</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Brand'}
            icon={<IoMdImages size="18" />}
            isMultiSelect={false}
            items={
              brands ? brands.spoofing_entities.map((brand) => brand.entity_name) : []
            }
            placeholder={'Search Brands...'}
            selectedItems={[selectedBrand?.entity_name]}
            setSelectedItems={([brandName]) => {
              const selectedBrand = brands.spoofing_entities?.find(
                (b) => b.entity_name === brandName,
              )
              setSelectedBrand(selectedBrand)
            }}
            showSearchBar={true}
            w="100%"
          />

          <FormHelperText>Brand to action all entities under</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Credit Card Number (or BIN)</FormLabel>

          <Input
            onChange={(e) => setCreditCardNumber(e.target.value)}
            placeholder="1234 5678 9012 3456 or 123456"
            value={creditCardNumber}
          />

          <FormHelperText>
            Enter the full credit card number found on the dark web.
          </FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Expiration Date</FormLabel>

          <Input
            onChange={(e) => setExpirationDate(e.target.value)}
            type="date"
            value={expirationDate}
          />

          <FormHelperText>Enter the expiration date of the credit card.</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Cardholder Name</FormLabel>

          <Input
            onChange={(e) => setCardholderName(e.target.value)}
            placeholder="John Doe"
            value={cardholderName}
          />

          <FormHelperText>
            Enter the cardholder name found on the dark web.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Cardholder Address</FormLabel>

          <Input
            onChange={(e) => setCardholderAddress(e.target.value)}
            placeholder="1234 Main St, Anytown, USA"
            value={cardholderAddress}
          />

          <FormHelperText>
            Enter the cardholder address found on the dark web.
          </FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Location Url</FormLabel>

          <Input
            onChange={(e) => setflaggedUrl(e.target.value)}
            placeholder="https://darkweb.example.com"
            ref={firstField}
            value={flaggedUrl}
          />

          <FormHelperText>
            Enter the URL where the credit card information was found.
          </FormHelperText>
        </FormControl>

        <Button
          _hover={{ bgColor: DOPPEL_CYBER_BLUE_SHADE }}
          bgColor={error ? DOPPEL_ERROR : DOPPEL_CYBER_BLUE}
          color={'white'}
          isDisabled={
            error || isUploading || !flaggedUrl || !creditCardNumber || !expirationDate
          }
          isLoading={isUploading}
          loadingText="Uploading"
          onClick={onSubmitDarkWebCreditCard}
        >
          {error ? 'Error uploading' : 'Upload'}
        </Button>

        <Collapse animateOpacity in={error != null}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_ERROR}
            borderRadius="lg"
            direction="column"
            marginTop={2}
            padding="4"
            textColor="white"
          >
            <Text fontSize="lg" fontWeight="bold" marginBottom={2}>
              Error
            </Text>

            <Text>{error?.message || 'An unknown error occurred.'}</Text>

            {flaggedUrl && <Text>URL: {flaggedUrl}</Text>}
          </Flex>
        </Collapse>

        <Collapse animateOpacity in={report}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_SUCCESS}
            borderRadius="lg"
            direction="column"
            padding="4"
            textColor={'white'}
          >
            <UploadedDarkwebReport
              brand_id={report?.original_entity_id}
              flagged_url={report?.flagged_url}
              id={report?.id}
              platform={report?.type}
              status={report?.report_status}
            />
          </Flex>
        </Collapse>

        <Collapse animateOpacity in={errors.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_ERROR}
            borderRadius="lg"
            direction="column"
            marginTop={2}
            padding="4"
            textColor={'white'}
          >
            {errors.map((error) => {
              return (
                <ErrorReportReason
                  flagged_url={error.flagged_url}
                  key={error.flagged_url}
                  reason={error.reason}
                />
              )
            })}
          </Flex>
        </Collapse>
      </Stack>
    </SideDrawer>
  )
}
