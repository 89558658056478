import { useEffect } from 'react'

/**
 * Hook to save and restore scroll position
 * https://dev.to/renegadedev/save-scroll-state-in-react-when-visiting-other-page-with-a-custom-hook-57nk
 */

const scrollPositions = {}

// pageIdentifier is any unique key for the page
const usePreserveScrollPosition = (pageIdentifier: string) => {
  useEffect(() => {
    // Get the scroll state if it exists

    const pageScrollPosition = scrollPositions[pageIdentifier]

    // If it exists then set the scroll position of our page

    if (pageScrollPosition) {
      // this is the hacky part, but necessary because the data may not be rendered yet
      setTimeout(() => {
        window.scrollTo({
          top: pageScrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      }, 50)
    }

    const saveScroll = () => {
      scrollPositions[pageIdentifier] = window.scrollY
    }

    window.addEventListener('scroll', saveScroll)

    return () => {
      window.removeEventListener('scroll', saveScroll)
    }
  }, [pageIdentifier])
}

export default usePreserveScrollPosition
