import { CollectionReportChangeType } from '@/utils/constants'
import {
  ReportStatus,
  CollectionReportChangeType as AlertType,
} from '@/generated/enums'
import moment from 'moment'
/**
 * Function to get the last reported date from audit log where the report status changed to 'reported'
 */
export const getLastReportedAt = (auditLog: any) => {
  const reportedChanges = auditLog?.filter(
    (change) =>
      change.type === CollectionReportChangeType.REPORT_STATUS &&
      change.new_value === ReportStatus.REPORTED,
  )

  if (!reportedChanges || reportedChanges.length == 0) {
    return null
  }

  const latestResolvedDate = reportedChanges.reduce((latest, change) => {
    const currentreportedAt = new Date(change.timestamp)
    return currentreportedAt > latest ? currentreportedAt : latest
  }, null)

  const momentDate = moment.utc(latestResolvedDate)

  return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS')
}

/**
 * Function to get the last resolved date from audit log where the report status changed to 'resolved'
 */
export const getLastResolvedAt = (auditLog: any) => {
  const resolvedChanges = auditLog?.filter(
    (change) =>
      change.type === CollectionReportChangeType.REPORT_STATUS &&
      change.new_value === ReportStatus.RESOLVED,
  )

  if (!resolvedChanges || resolvedChanges.length == 0) {
    return null
  }

  const latestResolvedDate = resolvedChanges.reduce((latest, change) => {
    const currentResolvedAt = new Date(change.timestamp)
    return currentResolvedAt > latest ? currentResolvedAt : latest
  }, null)

  const momentDate = moment.utc(latestResolvedDate)

  return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS')
}

export const shouldDisplayAuditLogExternal = (
  comment: { type: string },
  isEmployeeView: boolean,
) => comment['type'] !== AlertType.INTERNAL_NOTES || isEmployeeView
