import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  Textarea,
  Collapse,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import {
  useCreateDarkWebCreditCardReportMutation,
  useGetPlatformSubtypesByNameQuery,
} from '../../../generated/graphql'
import { useOrgID, getOrgData, useUserID } from '../../../hooks/id_token_claims'
import SideDrawer from '../../shared/side_drawer'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_ERROR,
  DOPPEL_SUCCESS,
} from '../../../utils/style'
import { useSelectedReportType } from '@/pages/reports'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import {
  PlatformName,
  PlatformSubtype,
  ReportClassification,
  ReportSource,
  ReportStatus,
} from '@/generated/enums'
import { Order_By, useGetBrandNamesQuery } from '@/generated/graphql'
import DropdownMenu from '../../shared/forms/dropdown_menu'
import { IoMdImages } from 'react-icons/io'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useAtom } from 'jotai'
import { UploadedDarkwebReport } from '../form_components/uploaded_darkweb_report'
import { v4 as uuidv4 } from 'uuid'
import { parseYearMonthToLastDate } from './utils'

export default function UploadBulkDarkWebCreditCardLeakReportModal({
  isOpen,
  onClose,
  refreshFunc,
}) {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const [csvData, setCsvData] = useState('')
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const [errors, setErrors] = useState([])
  const [reports, setReports] = useState([])
  const firstField = useRef()

  const [createDarkWebCreditCardReport] = useCreateDarkWebCreditCardReportMutation()
  const { data: subtypeData } = useGetPlatformSubtypesByNameQuery({
    variables: { name: PlatformSubtype.CREDIT_CARD_LEAKS },
  })
  const creditCardLeaksPlatformSubtypeId = subtypeData?.platform_subtypes?.[0]?.id

  const { data: brands } = useGetBrandNamesQuery({
    variables: {
      where: { organization_id: { _eq: orgId } },
      order_by: [{ entity_name: Order_By.Asc }],
    },
  })

  if (
    selectedReportType !== ReportType.DARK_WEB ||
    selectedReportExposureType !== DarkWebPlatforms.CREDIT_CARD_LEAKS
  ) {
    return <></>
  }

  const reset = () => {
    setCsvData('')
    setSelectedBrand(null)
    setIsUploading(false)
    setErrors([])
    setReports([])
  }

  const onSubmitDarkWebCreditCard = () => {
    if (!creditCardLeaksPlatformSubtypeId) return
    setReports([])
    setErrors([])

    setIsUploading(true)

    const parsedData = csvData
      .split(/\n+/)
      .map((line) => {
        // Set this to whatever value you want to use as an empty representation
        const emptyValuePlaceholder = 'n/a'

        const fields = line.split(/[\t,]+/).map((item) => item.trim())

        // Bin and expirationDate should always be present (first two fields)
        const bin = fields[0] || null
        const expirationDate = fields[1] || null

        // Remaining fields are optional (if they don't exist, set to 'n/a' in the sheet before copying the data)
        const creditCardNumber = fields[2] === emptyValuePlaceholder ? null : fields[2]
        const flaggedUrl = fields[3] === emptyValuePlaceholder ? null : fields[3]
        const cardholderName = fields[4] === emptyValuePlaceholder ? null : fields[4]
        const cardholderAddress = fields[5] === emptyValuePlaceholder ? null : fields[5]

        return bin && expirationDate
          ? {
              bin,
              expirationDate,
              flaggedUrl: flaggedUrl,
              cardholderName: cardholderName,
              cardholderAddress: cardholderAddress,
              creditCardNumber: creditCardNumber,
            }
          : null
      })
      .filter(Boolean)

    const reportSubmissions = parsedData.map(
      ({
        bin,
        expirationDate,
        flaggedUrl,
        cardholderName,
        cardholderAddress,
        creditCardNumber,
      }) => {
        const darkWebId = uuidv4()

        const submitInput = {
          organization_id: orgData && orgData.id,
          brand_id: selectedBrand && selectedBrand.id,
          flagged_url: darkWebId.toString(),
          type: PlatformName.CREDIT_CARD_LEAKS,
          platform_subtype_id: creditCardLeaksPlatformSubtypeId,
          source: ReportSource.UI_UPLOAD,
          report_status: ReportStatus.NEEDS_ACTION,
          spoofing_status: ReportClassification.SUSPICIOUS,
          uploader_id: userId,
          suspicion_score: 10,
          classification: ReportClassification.SUSPICIOUS,
          location: flaggedUrl,
          dark_web_id: darkWebId,
          dark_web_network: null,
          credit_card_number: creditCardNumber,
          bin: Number(bin),
          expiration_date: parseYearMonthToLastDate(expirationDate),
          cardholder_name: cardholderName,
          cardholder_address: cardholderAddress,
        }

        return createDarkWebCreditCardReport({
          variables: submitInput,
        })
          .then((result) => {
            const resultingReport = result.data?.insert_spoofing_reports?.returning?.[0]
            if (resultingReport) {
              setReports((prev) => [...prev, resultingReport])
            } else if (result?.errors) {
              setErrors((prev) => [
                ...prev,
                { location: flaggedUrl || 'Unknown', error: result.errors[0].message },
              ])
            }
          })
          .catch((error) => {
            setErrors((prev) => [
              ...prev,
              {
                location: flaggedUrl || 'Unknown',
                error: error.message || 'Unknown error occurred',
              },
            ])
          })
      },
    )

    Promise.all(reportSubmissions).finally(() => {
      setIsUploading(false)
      if (errors.length === 0) {
        setCsvData('')
        setTimeout(() => {
          refreshFunc()
        }, 1500)
      }
    })
  }

  return (
    <SideDrawer
      firstField={firstField}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
    >
      <Stack spacing="24px">
        <Heading as="h2" size="md">
          Upload Bulk Dark Web Credit Card Alert
        </Heading>

        <FormControl>
          <FormLabel>Brand</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Brand'}
            icon={<IoMdImages size="18" />}
            isMultiSelect={false}
            items={
              brands ? brands.spoofing_entities.map((brand) => brand.entity_name) : []
            }
            placeholder={'Search Brands...'}
            selectedItems={[selectedBrand?.entity_name]}
            setSelectedItems={([brandName]) => {
              const selectedBrand = brands.spoofing_entities?.find(
                (b) => b.entity_name === brandName,
              )
              setSelectedBrand(selectedBrand)
            }}
            showSearchBar={true}
            w="100%"
          />

          <FormHelperText>Brand to action all entities under</FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>CSV Input</FormLabel>

          <Textarea
            onChange={(e) => setCsvData(e.target.value)}
            placeholder="123456, 2024-01, 12341234561234, www.darkwebmarket.com, John Doe, cardholder_address"
            ref={firstField}
            value={csvData}
          />

          <FormHelperText>
            Enter the CSV data with values in the following order: bin, expiration_date,
            credit_card_number, source_url, cardholder_name, cardholder_address. Each
            entry should be on a new line. Bin and expiration date are mandatory fields,
            the rest should be set to n/a if they are not present.
          </FormHelperText>
        </FormControl>

        <Button
          _hover={{ bgColor: DOPPEL_CYBER_BLUE_SHADE }}
          bgColor={DOPPEL_CYBER_BLUE}
          color={'white'}
          isDisabled={isUploading || !csvData || !selectedBrand}
          isLoading={isUploading}
          loadingText="Uploading"
          onClick={onSubmitDarkWebCreditCard}
        >
          Upload
        </Button>

        <Collapse animateOpacity in={errors.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_ERROR}
            borderRadius="lg"
            direction="column"
            marginTop={2}
            padding="4"
            textColor={'white'}
          >
            <Text fontSize="lg" fontWeight="bold" marginBottom={2}>
              Errors
            </Text>

            {errors.map((error) => (
              <Flex
                bgColor="rgba(255, 0, 0, 0.2)"
                borderRadius="md"
                direction="column"
                key={error.location}
                marginBottom={2}
                padding="4"
              >
                <Text fontWeight="bold">URL: {error.location}</Text>

                <Text>Error: {error.error}</Text>
              </Flex>
            ))}
          </Flex>
        </Collapse>

        <Collapse animateOpacity in={reports.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_SUCCESS}
            borderRadius="lg"
            direction="column"
            padding="4"
            textColor={'white'}
          >
            <Text fontSize="lg" fontWeight="bold" marginBottom={2}>
              Successfully Uploaded Reports
            </Text>

            {reports.map((report) => (
              <UploadedDarkwebReport
                brand_id={report.original_entity_id}
                flagged_url={report.flagged_url}
                id={report.id}
                key={report.id}
                platform={report.type}
                status={report.report_status}
              />
            ))}
          </Flex>
        </Collapse>
      </Stack>
    </SideDrawer>
  )
}
